<!-- 企业认证首页弹窗 -->
<template>
  <div class="sld_authen_screen" :style="{height:height+'px'}">
    <div class="sld_authen_screen_main">
      <img class="sld_authen_screen_bg" src="@/assets/auth_bg.png"/>
      <img class="sld_authen_screen_close" @click="close" src="@/assets/auth_close.png"/>
      <div class="sld_authen_screen_btn" @click="goto">{{L['立即认证']}}</div>
    </div>
  </div>
</template>

<script>
  import { getCurrentInstance, ref } from 'vue'
  import { useRouter } from 'vue-router'
  export default {
    name: 'AuthScreen',
    setup(props, { emit }) {
      const router = useRouter();
      const { proxy } = getCurrentInstance();
      const L = proxy.$getCurLanguage()
      const height = ref(document.documentElement.clientHeight);
      
      const close = () => {
        emit('closeAuth')
      }
      window.scroll(0, 0);

      const goto =()=> {
        emit('closeAuth');
        router.push('/member/authen');
      }

      return { close, height, goto,L }
    }
  }
</script>

<style lang="scss" scoped>
  .sld_authen_screen {
    position: fixed;
    background: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    z-index: 99999;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .sld_authen_screen_main {
      position: relative;
      width: 620px;

      .sld_authen_screen_bg {
        width: 100%;
      }
      .sld_authen_screen_close {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 9;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .sld_authen_screen_btn {
        position: absolute;
        left: 50%;
        margin-left: -84px;
        bottom: 36px;
        z-index: 9;
        width: 168px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        color: #3859F5;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
</style>