// 头部登陆注册导航栏
<template>
     <div class="header_wrap">
          <div class="header">
               <div class="header_left">
                    <span class="hello">{{ L['您好，欢迎来到'] }}{{ configInfo.data.basic_site_name }}</span>
                    <template v-if="loginFlag">
                         <span class="role" v-if="memberInfo.memberType == 1">{{ L['个人'] }}</span>
                         <span class="role" v-else-if="memberInfo.memberType == 2">{{ L['设计师'] }}</span>
                         <span class="role" v-else-if="memberInfo.memberType == 3">{{ L['企业'] }}</span>
                         <span class="role" v-else>{{ L['业务员'] }}</span>
                         <span class="register h1" @click="goToByPush('/member/index')">{{
                              memberInfo.memberNickName ||
                              memberInfo.memberName
                         }}</span>
                         <span class="register h1 login_out" @click="loginOut()">[{{ L['退出'] }}]</span>
                    </template>
                    <template v-else>
                         <span class="register h1" @click="goToByPush('/login')">{{ L['请登录'] }}</span>
                         <span class="register h1" @click="goToByPush('/register')">{{ L['免费注册'] }}</span>
                    </template>
               </div>
               <div class="header_right">
                    <ul>
                         <li v-if="fromHome" @click="goToByPush('/', {})">
                              <div class="li_item">{{ L['商城首页'] }}</div>
                         </li>
                         <li>
                              <div class="has_more li_item">
                                   <a href="javascript:void(0)" class="li_item_a"
                                        @click="goToByPush('/member/order/list')">{{
                                             L['我的订单'] }}</a>
                                   <div class="li_item_more">
                                        <a href="javascript:void(0)" class="li_item_more_item"
                                             @click="goToByPush('/member/order/list', { 'orderState': 20 })">{{
                                                  L['待付款订单'] }}</a>
                                        <a href="javascript:void(0)" class="li_item_more_item"
                                             @click="goToByPush('/member/order/list', { 'orderState': 41 })">{{
                                                  L['待收货订单'] }}</a>

                                   </div>
                              </div>
                         </li>
                         <li @click="goToByPush('/member/index')">
                              <div class="li_item">{{ L['用户中心'] }}</div>
                         </li>
                         <li>
                              <div class="has_more li_item">
                                   {{ L['我的收藏'] }}
                                   <div class="li_item_more">
                                        <a href="javascript:void(0)" class="li_item_more_item"
                                             @click="goToByPush('/member/collect')">{{
                                                  L['产品收藏'] }}</a>
                                        <a href="javascript:void(0)" class="li_item_more_item"
                                             @click="goToByPush('/member/collect', { type: 'store' })">{{ L['供应商收藏'] }}</a>

                                   </div>
                              </div>
                         </li>

                         <li>
                              <div class="li_item" @click="goToByPush('/msg/list', { curTplTypeCode: 'msg_all' })">{{
                                   L['消息'] }}</div>
                         </li>
                         <li>
                              <div class="li_item">{{ L['小程序'] }}</div>
                              <div v-if="configInfo.data.foot_qr_code1" class="li_item_code"
                                   :style="`background-image:url('` + configInfo.data.foot_qr_code1 + `')`"></div>
                         </li>
                    </ul>
               </div>
          </div>
     </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentInstance, reactive, watchEffect, ref, watch } from "vue";
export default {
     name: "NavTopBar",
     setup() {
          const { proxy } = getCurrentInstance();
          const store = useStore();
          const L = proxy.$getCurLanguage();
          const memberInfo = ref(store.state.memberInfo);
          const loginFlag = ref(store.state.loginFlag);
          const router = useRouter();
          const fromHome = ref(false); //是否不是从商城首页进入
          const configInfo = reactive({
               data: store.state.configInfo
          })
          watchEffect(() => {
               if (window.history && window.history.state && window.history.state.current != '/') {
                    fromHome.value = true;
               }
               loginFlag.value = store.state.loginFlag;
          })

          watch(() => store.state.memberInfo.memberType, (ov, nv) => {
               memberInfo.value = store.state.memberInfo;
          })

          //去注册
          const goRegister = () => {
               router.push({
                    path: `/register`,
                    query: {
                         redirect: encodeURIComponent(window.location.href)
                    }
               });
          };

          //前往具体的页面，type为具体的页面地址，param为参数对象
          const goToByPush = (type, param = {}) => {
               router.push({
                    path: type,
                    query: param
               });
          };
          //退出登录
          const loginOut = () => {
               proxy.$post('v3/frontLogin/front/oauth/logout', {
                    refresh_token: localStorage.getItem('refresh_token')
               }).then(res => {
                    if (res.state == 200) {
                         store.commit("clearAllData"); //清除数据
                         proxy.$socket.disconnect()
                         goToByPush("/login", {});
                    }
               })
          };
          return { L, goRegister, memberInfo, loginOut, loginFlag, goToByPush, configInfo, fromHome };
     }
};
</script>

<style lang="scss" scoped>
.header_wrap {
     width: 100%;
     height: 33px;
     background: #F2F2F2;
     display: flex;
     align-items: center;
     justify-content: center;

     .header {
          width: 1210px;
          height: 33px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .header_left {
               height: 100%;
               font-size: 12px;
               color: $colorG;
               line-height: 36px;
               display: flex;

               .hello {
                    cursor: default;
                    margin-right: 10px;
                    color: $colorG;
               }

               .role {
                    width: 42px;
                    text-align: center;
                    color: $colorMain;
                    cursor: default;
               }

               .h1 {
                    margin: 0 5px;
                    cursor: pointer;

                    &:hover {
                         color: $colorMain;
                    }
               }
          }

          .header_right {
               height: 100%;

               ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .personal_center {
                         width: 121px;
                         height: 30px;
                         display: flex;
                         align-items: center;
                         justify-content: center;
                    }

                    li {
                         position: relative;
                         float: left;
                         text-align: center;
                         border-right: 1px solid #ddd;
                         padding: 0 18px;
                         height: 12px;

                         .li_item {
                              position: relative;
                              cursor: pointer;
                              font-size: 12px;
                              line-height: 12px;
                              color: $colorTitle2;
                              height: 33px;

                              &:hover {
                                   color: $colorMain;

                                   &.has_more {
                                        &:before {
                                             border-top-color: $colorMain;
                                        }
                                   }

                                   .li_item_more {
                                        display: block;
                                   }

                                   .li_item_a {
                                        color: $colorMain;
                                   }
                              }

                              &.has_more {
                                   padding-right: 12px;

                                   &:before,
                                   &:after {
                                        position: absolute;
                                        right: -2px;
                                        display: block;
                                        width: 0;
                                        height: 0;
                                        content: " ";
                                        border: 4px solid transparent;
                                        border-radius: 2px;
                                   }

                                   &:before {
                                        top: 3px;
                                        border-top: 5px solid #888;
                                   }

                                   &:after {
                                        top: 1px;
                                        border-top: 5px solid #f7f7f7;
                                   }
                              }

                              .li_item_a {
                                   color: $colorTitle2;
                              }

                              .li_item_more {
                                   position: absolute;
                                   width: 80px;
                                   padding: 5px 3px;
                                   left: 50%;
                                   transform: translateX(-50%);
                                   top: 21px;
                                   background: #fff;
                                   box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
                                   z-index: 999;
                                   display: none;

                                   &:before,
                                   &:after {
                                        position: absolute;
                                        top: -11px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        -webkit-transform: translateX(-50%);
                                        display: block;
                                        content: " ";
                                        width: 0;
                                        height: 0;
                                        border: 5px solid transparent;
                                        border-bottom: 6px solid #dedede;
                                   }

                                   &:after {
                                        top: -10px;
                                        border-bottom: 6px solid #fff;
                                   }

                                   .li_item_more_item {
                                        display: block;
                                        text-align: center;
                                        color: #999;
                                        line-height: 12px;
                                        padding: 8px 0;

                                        &:hover {
                                             color: $colorMain;
                                        }
                                   }
                              }
                         }

                         &:hover {
                              .li_item_code {
                                   display: block;
                              }
                         }

                         .li_item_code {
                              display: none;
                              position: absolute;
                              top: 21px;
                              left: 50%;
                              margin-left: -80px;
                              z-index: 999;
                              width: 180px;
                              height: 180px;
                              background-size: contain;
                              background-position: center;
                              background-repeat: no-repeat;
                              border: 1px solid $colorJ;
                              box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

                              &:hover {
                                   display: block;
                              }

                              &:before,
                              &:after {
                                   position: absolute;
                                   top: -11px;
                                   left: 50%;
                                   transform: translateX(-50%);
                                   -webkit-transform: translateX(-50%);
                                   display: block;
                                   content: " ";
                                   width: 0;
                                   height: 0;
                                   border: 5px solid transparent;
                                   border-bottom: 6px solid #dedede;
                              }

                              &:after {
                                   top: -10px;
                                   border-bottom: 6px solid #fff;
                              }
                         }

                         &:last-child {
                              border-right-width: 0;
                              padding-right: 0;
                         }
                    }
               }
          }
     }
}
</style>